import React, { useState, useEffect } from 'react';

const GetBooksInfo: React.FC = () => {
  const [books, setBooks] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('/api/GetBooksInfo', {
      headers: {
        'Accept': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok) { throw new Error('Failed to fetch books info'); }
      return response.json();
    })
    .then(data => setBooks(data.books))
    .catch(error => setError(error.message));
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2>Latest reported books in the library</h2>
      {books.length > 0 ? (
        <ul>
          {books.map((book:any, index) => (
            <li key={index}>
              <h3>{book.name}</h3>
              <p>Author: {book.author}</p>
              <p>Tags: {book.tags}</p>
              <p>Summary: {book.summary}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No books to display. Please upload a new photo.</p>
      )}
    </div>
  );
};

export default GetBooksInfo;